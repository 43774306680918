
import { computed, defineComponent } from 'vue';
import LearningCatalogueAtoms from '@/components/catalogue/LearningCatalogueAtoms.vue';
import { useI18n } from '@/plugins/vue-i18n';
import { AtomType } from '@/domains/atom';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';
import { CatalogueAtomCard } from '@/components/catalogue/CatalogueAtomCard';

export default defineComponent({
  name: 'LearningCatalogueTests',
  components: { LearningCatalogueAtoms, CatalogueAtomCard },
  setup() {
    const { t } = useI18n();
    const title = t('LearningCatalogue.Tests.title');
    const atomTypes = computed(() => [AtomType.QUIZ, AtomType.MULTILEVELTEST]);
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: false });
    const { getAtomPreviewUrl } = useCatalogueAtomCard();

    return {
      title, atomTypes, onAssignAtom, getAtomPreviewUrl,
    };
  },
});
